import {createStore} from 'vuex'
import { userOut } from '@/api/user'
import {nextTick} from "vue";

const store = createStore({
    state: {
        token: localStorage.getItem('token') || '',
        userInfo: JSON.parse(localStorage.getItem('userInfo')) || {},
        signInfo: JSON.parse(localStorage.getItem('signInfo')) || {}
    },
    getters: {
        token: state => state.token,
        userInfo: state => state.userInfo
    },
    mutations: {
        SET_TOKEN: (state, data) => {
            state.token = data
            localStorage.setItem('token', data)
        },
        SET_USERINFO: (state, data) => {
            localStorage.setItem('userInfo', JSON.stringify(data))
            state.userInfo = data
        },
        SET_SIGNINFO: (state, data) => {
            localStorage.setItem('signInfo', JSON.stringify(data))
            state.signInfo = data
        }
    },
    actions: {
        login({commit}, data) {
            commit('SET_TOKEN', data.token);
            commit('SET_USERINFO', data)
        },
        logout({commit}, data) {
            if(store.getters['token']) userOut({})
            nextTick(() => {
                commit('SET_TOKEN', '')
                commit("SET_USERINFO", {})
                window.location.href = '/home'
            })
        }
    }
})

export default store