<template>
  <div class="sidebar">
    <el-drawer
        v-model="showSidebarModel"
        :show-close="false"
        direction="ltr"
        size="240"
        modal-class="sidebar-drawer"
        @close="close">
      <template #header>
        <div>
          <i class="icon-menu" @click="showSidebarModel = false"></i>
        </div>
      </template>
      <div class="menu-list">
        <template v-for="(item, index) in menuList">
          <div class="menu-list-item" :class="{'cur': activeIndex === index}" @click="change(item, index)">
            <i :class="`icon-${item.icon}`"></i>
            {{item.title}}
          </div>
          <div v-if="index === 2" class="h-line"></div>
        </template>
        <div class="menu-list-item" @click="handleLogout()"><i class="icon-logout"></i>Log Out</div>
      </div>
    </el-drawer>
    <Layer :layer="layer" @confirm="logout()">
      <div class="font-size-17 font-weight-bold">Log out confirmation</div>
      <div class="font-size-12 m-t-15">Are you sure you want to log out?</div>
    </Layer>
  </div>
</template>

<script setup>
import router from '/src/router/index'
import {ref, reactive, watch} from 'vue'
import { useRoute } from 'vue-router';
import Layer from '@/components/Layer'
import { useStore } from "vuex";

const store = useStore()
const route = useRoute();
const props = defineProps({
  showSidebar: {
    type: Boolean,
    default: false
  }
})
const userInfo = store.getters['userInfo'];
const emit = defineEmits(['toggleSidebar'])
const layer = ref({
  show: false,
  title: '',
  showButton: true
})
let activeIndex = ref(-1)
let menuList = reactive([]);
const showSidebarModel = ref(props.showSidebar);

watch(() => props.showSidebar, (newV, oldV) => {
  showSidebarModel.value = newV;
}, {deep: true, immediate: true})
const close = () => {
  emit('toggleSidebar', false)
}
const getMenuList = () => {
  router.options.routes.map((x,y) => {
    if(!x.hidden) {
      // 非管理员不展示user manage页面
      if(!(x.path === '/user-management' && userInfo.permBooking !== 'Admin (All Users)')) {
        menuList.push({
          title: x.name,
          path: x.path,
          icon: x.icon
        })
      }
    }
  });
  console.log(menuList)
}
const change = (item, index) => {
  showSidebarModel.value = false;
  router.push(menuList[index].path)
}
const changeIndex = (layou) => {
  let temIndex = -1
  menuList.forEach((item, index) => {
    if (item.title === layou.meta?.title) {
      temIndex = index
    }
  })
  activeIndex.value = temIndex
}
const handleLogout = () => {
  layer.value.show = true;
  layer.value.confirmTxt = 'Log out';
  layer.value.width = 380;
}
const logout = () => {
  store.dispatch('logout')
}
getMenuList();
changeIndex(route);
router.afterEach((to, from) => {
  changeIndex(to)
})
</script>

<style scoped lang="scss">
.menu-list {
  color: var(--system-base-color);
  .menu-list-item {
    margin-bottom: 28px;
    padding: 8px 15px;
    cursor: pointer;
    font-weight: bold;
    border-radius: 8px;
    font-size: 14px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    &:hover {
      background: var(--system-hover-color);
    }
    &.cur {
      background: var(--system-base-color);
      color: #fff;
    }
    & > i {
      margin-right: 10px;
      font-size: 18px;
      width: 18px;
      display: inline-block;
    }
  }
}
.h-line {
  border-top: 1px solid #DBDBDB;
  margin-bottom: 28px;
}
:deep {
  .icon-menu {
    &:hover {
      opacity: 0.7;
    }
  }
  .sidebar-drawer {
    //width: 245px;
    background: none!important;
  }
  .el-drawer {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.15);
    border-right: 1px solid #F0F0F0;
  }
  .el-drawer__header {
    margin: 0;
    padding: 0 25px 0 25px;
    height: 60px;
    color: #333;
    i {
      font-size: 22px;
      display: inline-block;
      cursor: pointer;
      color: var(--system-base-color);
    }
  }
  .el-drawer__body {
    padding: 30px 25px;
  }
}
</style>