import {createRouter, createWebHistory} from 'vue-router'
import Layout from '/src/layout/index.vue'

const routes = [
    {
        path: '/',
        name: 'Event Types',
        component: Layout,
        redirect: '/event-types',
        icon: "list",
        children: [
            {
                path: '/event-types',
                component: () => import('/src/views/dashboard/index.vue'),
                meta: {
                    title: "Event Types"
                },
                children: [
                    {
                        path: '',
                        component: () => import('/src/views/dashboard/event.vue'),
                        meta: {
                            title: "Event Types"
                        }
                    },
                    {
                        path: 'edit-event',
                        component: () => import('/src/views/dashboard/edit.vue'),
                        meta: {
                            title: "Edit Event"
                        }
                    }
                ]
            }
        ]
    },
    {
        path: '/scheduled-events',
        name: 'Scheduled Events',
        component: Layout,
        redirect: '/scheduled-events',
        icon: "calendar",
        children: [
            {
                path: '/scheduled-events',
                component: () => import('/src/views/scheduled/index.vue'),
                meta: {
                    title: "Scheduled Events"
                }
            }
        ]
    },
    {
        path: '/user-management',
        name: 'User Management',
        component: Layout,
        redirect: '/user-management',
        icon: "contact",
        children: [
            {
                path: '/user-management',
                component: () => import('/src/views/user/index.vue'),
                meta: {
                    title: "User Management",

                }
            }
        ]
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Layout,
        redirect: '/settings',
        icon: "settings",
        children: [
            {
                path: '/settings',
                component: () => import('/src/views/settings/index.vue'),
                meta: {
                    title: "Settings"
                }
            }
        ]
    },
    {
        path: '/home',
        name: 'Home',
        component: () => import('/src/views/login/home.vue'),
        meta: {
            title: 'Home'
        },
        hidden: true
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('/src/views/login/login.vue'),
        meta: {
            title: 'Login'
        },
        hidden: true
    },
    {
        path: '/reset',
        name: 'Reset',
        component: () => import('/src/views/login/reset.vue'),
        meta: {
            title: 'Reset Password'
        },
        hidden: true
    },
    {
        path: '/signUp',
        name: 'SignUp',
        component: () => import('/src/views/login/signUp.vue'),
        meta: {
            title: 'Sign Up'
        },
        hidden: true
    },
    {
        path: '/signIn',
        name: 'SignIn',
        component: () => import('/src/views/login/signIn.vue'),
        meta: {
            title: 'Sign In'
        },
        hidden: true
    },
    {
        path: '/confirmation',
        name: 'Confirmation',
        component: () => import('/src/views/login/confirmation.vue'),
        meta: {
            title: 'Confirmation'
        },
        hidden: true
    },
    {
        path: '/choose',
        name: 'Choose',
        component: () => import('/src/views/login/choose.vue'),
        meta: {
            title: 'Choose'
        },
        hidden: true
    },
    {
        path: '/:id',
        component: () => import('/src/views/dashboard/join.vue'),
        beforeEnter: (to, from, next) => {
            if(to.query.link || to.query.code || to.query.success) {
                next()
            } else {
                next({
                    path: '/share',
                    query: { id:  to.params.id}
                })
            }
        },
        meta: {
            title: 'Join'
        },
        hidden: true
    },
    {
        path: '/reschedule/:paramId',
        component: () => import('/src/views/dashboard/reschedule.vue'),
        meta: {
            title: 'Reschedule'
        },
        hidden: true
    },
    {
        path: '/cancel/:paramId',
        component: () => import('/src/views/dashboard/cancel.vue'),
        meta: {
            title: 'Cancel'
        },
        hidden: true
    },
    {
        path: '/share',
        name: 'Share',
        component: () => import('/src/views/dashboard/share.vue'),
        meta: {
            title: 'Share'
        },
        hidden: true
    },
    {
        path: '/404',
        name: '404',
        component: () => import('/src/views/login/404.vue'),
        meta: {
            title: '404'
        },
        hidden: true
    },
    {
        path: '/:catchAll(.*)',
        name: 'not-found',
        component: () => import('/src/views/login/404.vue'),
        redirect: '/404',
        hidden: true
    }
]
let router = createRouter({
    routes,
    history: createWebHistory()
})
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title
    }
    next()
})
export default router;