<template>
  <div v-if="layer.show" v-drag="layer.show">
    <el-dialog
      v-model="layer.show"
      :title="layer.title"
      :width="layer.width"
      v-bind="$attrs"
      @close="close"
    >
      <slot></slot>
      <template #footer v-if="layer.showButton">
        <div>
          <el-button @click="close">{{layer.cancelTxt || 'Cancel'}}</el-button>
          <el-button type="primary" @click="confirm">{{layer.confirmTxt || 'Confirm'}}</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import drag from '@/directive/drag/index'
export default defineComponent({
  props: {
    layer: {
      type: Object,
      default: () => ({
        show: false,
        title: '',
        showButton: false,
        confirmTxt: 'Confirm',
        cancelTxt: 'Cancel'
      }),
      required: true
    }
  },
  directives: {
    drag
  },
  setup(props, ctx) {
    function confirm() {
      ctx.emit('confirm')
    }
    function close() {
      props.layer.show = false
    }
    return {
      confirm,
      close
    }
  }
})
</script>

<style lang="scss" scoped>
  :deep {
    .el-dialog {
      border-radius: 5px;
      .el-dialog__body {
        color: #333;
      }
      .el-dialog__footer {
        padding-top: 20px;
        text-align: right!important;
        display: flex;
        justify-content: flex-end;
      }
      .el-dialog__headerbtn .el-dialog__close {
        color: #333;
        &:hover {
          color: var(--system-base-color);
        }
      }
    }
  }
</style>