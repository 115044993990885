import axios from 'axios'
import store from '@/store'
import { ElMessage } from 'element-plus'
const baseURL = process.env.VUE_APP_BASE_URL_API

const service_http = axios.create({
    baseURL: baseURL,
    timeout: 10000,
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
    }
})

export const request_file = axios.create({
    baseURL: baseURL,
    timeout: 10000,
    headers: {
        'Content-Type': 'multipart/form-data'
    }
})

// 请求前的统一处理
service_http.interceptors.request.use(
    (config) => {
        const userInfo = store.getters['userInfo'];
        if(Object.keys(userInfo).length > 0 && (userInfo.work === '' || userInfo.usingOn === '') && config.url !== 'user/editinfo') {
            window.location.href = '/choose'
            return Promise.reject()
        } else {
            let data = JSON.parse(JSON.stringify(config.data || {}))
            data['token'] = store.getters['token']
            config.data = data
            return config
        }
    },
    (error) => {
        console.log(error) // for debug
        return Promise.reject(error)
    }
)

service_http.interceptors.response.use(
    (response) => {
        const res = response.data
        if (res.code === 0) {
            return res
        } else {
            showError(res)
            return Promise.reject(res)
        }
    },
    (error)=> {
        console.log(error) // for debug
        const badMessage = error.message || error
        const code = parseInt(badMessage.toString().replace('Error: Request failed with status code ', ''))
        showError({ code, message: badMessage })
        return Promise.reject(error)
    }
)

// 错误处理
export function showError(error) {
    // token过期，清除本地数据，并跳转至登录页面
    if (error.code === 401 ) {
        store.dispatch('logout')
        return false
    } else if (error.code === 403) {
        store.dispatch('logout')
        return false
    } else {
        ElMessage({
            message: error.msg || error.message || 'error',
            type: 'error',
            duration: 3 * 1000
        })
    }
}

export default service_http