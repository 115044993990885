let _debounceTimeout = null,
    _throttleRunning = false

/**
 * 防抖
 * @param {Function} fn 执行函数
 * @param {Number} delay 延时ms
 */
export const debounce = (fn, delay=500) => {
    clearTimeout(_debounceTimeout);
    _debounceTimeout = setTimeout(() => {
        fn();
    }, delay);
}
/**
 * 节流
 * @param {Function} fn 执行函数
 * @param {Number} delay 延时ms
 */
export const throttle = (fn, delay=500) => {
    if(_throttleRunning){
        return;
    }
    _throttleRunning = true;
    fn();
    setTimeout(() => {
        _throttleRunning = false;
    }, delay);
}

/**
 * 获取英文月份
 * @param {number} timestamp 传入时间戳
 * @param {string} type 简写/全写
 * @returns {string}
 */
export function getEngMonth(timestamp, type = 'simple') {
    if(type === 'simple') {
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        return months[(new Date(timestamp)).getMonth()];
    } else if(type === 'full') {
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        return months[(new Date(timestamp)).getMonth()];
    }
}

/**
 * 获取英文周名
 * @param {number} week 传入星期
 * @param {string} type 简写/全写
 */
export function getEngWeek(week, type = 'simple') {
    if(type === 'simple') {
        return ["Sun", 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][week];
    } else if(type === 'full') {
        return ["Sunday", 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][week];
    }
}

/**
 * 小于10的补0
 * @param {number} num
 * @returns {string}
 */
export function addZero(num) {
    if (num < 10) {
        return '0' + num;
    }
    return num.toString()
}

/**
 * 判断当前是否是移动端
 * @returns {Boolean}
 */
export function isMobile() {
    let userAgentInfo = navigator.userAgent;
    let Agents = ['Android', 'iPhone', 'SymbianOS', 'Windows Phone', 'iPad', 'iPod'];
    let getArr = Agents.filter(i => userAgentInfo.includes(i));
    return !!getArr.length;
}