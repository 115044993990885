import { createApp } from 'vue'
import App from './App.vue'
import router from './router/index'
import store from "@/store";
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import '/src/assets/css/base.scss'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import Clipboard from 'vue3-clipboard'
import vue3GoogleLogin from 'vue3-google-login'

const app = createApp(App)

app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(Clipboard, {
    autoSetContainer: true
})
app.use(vue3GoogleLogin, {
    clientId: '69698336747-5n06fr30357tpp82nfbr0danpjeaqt9v.apps.googleusercontent.com'
})

NProgress.configure({
    easing: 'ease',
    speed: 500,
    showSpinner: false,
    trickleSpeed: 200,
    minimum: 0.3
})

router.beforeEach((to, from, next) => {
    NProgress.start();
    next();
})

router.afterEach(() => {
    NProgress.done()
})

app.mount('#app')
