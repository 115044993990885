<template>
<div class="app-wrapper">
  <sidebar :showSidebar="showSidebar" @toggleSidebar="toggleSidebar"></sidebar>
  <div class="main-container">
    <navbar @toggleSidebar="toggleSidebar"></navbar>
    <div class="content-container">
      <router-view />
    </div>
  </div>
</div>
</template>

<script setup>
import {ref} from 'vue'
import sidebar from './components/sidebar-block.vue'
import navbar from './components/navbar-block.vue'

let showSidebar = ref(false)

const toggleSidebar = (isShow) => {
  showSidebar.value = isShow;
}

</script>

<style scoped lang="scss">
.app-wrapper {
  height: 100%;
  .main-container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .content-container {
    flex: 1;
    height: 100%;
    background: #fafafa;
    overflow-y: auto;
  }
}
</style>