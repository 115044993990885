<template>
  <router-view />
</template>

<script setup>
import {isMobile} from "@/utils";
import {useRoute} from "vue-router";

const route = useRoute()
setTimeout(() => {
  if((route.path !== '/reset' && route.path !== '/signUp') && isMobile()) {
    window.location.href = process.env.VUE_APP_MOBILE_DOMAIN
  }
},100)
</script>

<style>
#nprogress .bar {
  background: #006BF7 !important;
}
</style>
